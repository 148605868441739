<template>
  <section
    id="about-mission-vision"
    class="py-0"
  >
    <v-row
      class="px-0 mx-0 pt-16"
      style="background-color: #ebedee;"
    >
      <v-col
        cols="12"
        sm="6"
        class="px-0 mx-0"
      >
        <v-responsive
          class="d-flex align-center mx-auto"
          height="100%"
          max-width="800"
          width="90%"
        >
          <base-subheading
            weight="regular"
            title="VISION"
            class="text-center font-weight-bold"
          />
          <p class="mx-10 black--text">
            Our vision is to be a world-class leader in hand barrier protection gloves that contribute to the overall wellbeing, health, safety and hygiene of mankind.
          </p>
          <br>
          <br>
          <br>
          <br>
        </v-responsive>
      </v-col>
      <v-col
        cols="12"
        sm="6"
        class="px-0 py-0 mx-0"
      >
        <v-responsive
          class="d-flex align-center mx-auto"
          height="100%"
          max-width="800"
          width="90%"
        >
          <base-subheading
            weight="regular"
            title="MISSION"
            class="text-center font-weight-bold"
          />
          <p class="mx-10 black--text">
            Leading solution provider in the areas of design, engineering, process technology and manufacturing in the nitrile glove industry, as well as to grow our relationships with our business partners for an ethical and sustainable business endeavor. Manufacturing superior quality products through continuous improvement and innovation.
          </p>
          <br>
          <br>
        </v-responsive>
      </v-col>
    </v-row>
  </section>
</template>

<script>
  export default {
    name: 'SectionAboutMissionVision',
  }
</script>
